module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bemooore","short_name":"Bemoore","description":"Bemoore is a digital agency based in Brussels. We design and build websites for small businesses to help them increase their visibility on the web. We build e-commerce solutions with the latest technologies such as Shopify.","lang":"en","display":"standalone","icon":"src/images/logo.png","start_url":"/","purpose":"maskable","background_color":"#663399","theme_color":"#fff","localize":[{"start_url":"/en/","lang":"en","name":"Bemoore","short_name":"Bemoore","description":"Bemoore is a digital agency based in Brussels. We design and build websites for small businesses to help them increase their visibility on the web. We build e-commerce solutions with the latest technologies such as Shopify."}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"389bffd6caeb5526780db18972290e04"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["en","fr","nl"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
